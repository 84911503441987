<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button class="product-card__quickview" type="button" aria-label="Vista rápida" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <div *ngIf="product.badges.length" class="product-card__badges-list">
        <div *ngIf="product.badges.includes('Oferta')" class="product-card__badge product-card__badge--sale">Oferta</div>
        <div *ngIf="product.badges.includes('Mas Vendidos')" class="product-card__badge product-card__badge--hot">Mas Vendidos</div>
        <div *ngIf="product.badges.includes('Nuevo')" class="product-card__badge product-card__badge--new">Nuevo</div>
    </div>

    <div class="product-card__image product-image">
        <a appClick [routerLink]="root.product(product.id,product.urlAmigable)" *ngIf="product.images?.length" class="product-image__body">
            <img class="product-image__img" [src]="product.images[0]" [alt]="product.name" [ngClass]="{'imagen' : product.availability === 'No Disponible' && !this.storeSvc.configuracionSitio.SuperarInventario}">
        </a>
    </div>
    <div class="product-card__info">
        <div *ngIf="product.discountPerc > 0" >
            <div class="product-card__badge product-card__badge--sale tag-sale">- {{ product.discountPerc | percentPipe }}</div>
        </div>
        <div class="product-card__name">
            <a appClick [routerLink]="root.product(product.id,product.urlAmigable)">{{ product.name | titlecase  }}</a>
        </div>
        <div class="product-card__rating">
            <app-rating [value]="product.rating"></app-rating>
            <div class="product-card__rating-legend">{{ product.reviews }} Comentarios</div>
        </div>
        <ul *ngIf="featuredAttributes?.length" class="product-card__features-list">
            <li *ngFor="let attribute of featuredAttributes">
                {{ attribute.name | titlecase  }}:
                <ng-container *ngFor="let value of attribute.values; let last = last">
                    {{ value.name }}<ng-container *ngIf="!last">, </ng-container>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="product-card__actions">
        <div class="product-card__availability">
            Disponible:
            <span *ngIf="product.availability === 'Disponible'" class="text-success">Si</span>
            <span *ngIf="product.availability === 'No Disponible'" class="text-danger">No</span>
        </div>
        <div class="product-card__prices" *ngIf="this.storeSvc.configuracionSitio.MostrarPreciosSinLogueo || this.islogged==='true'">
            <ng-container *ngIf="product.compareAtPrice">
                <span class="product-card__new-price">{{ product.price|currencyFormat }}</span>{{ ' ' }}
                <span class="product-card__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice">
                {{ product.price|currencyFormat}}
            </ng-container>
        </div>
        <div class="product-card__buttons">
            <div class="product-card__addtocart btnquantyti">
                
                <app-input-number aria-label="Quantity" size="sm" [min]="1" [max]="maxCantidad(product)"
                    [(ngModel)]="quantity"></app-input-number>
                <button appClick
                    [disabled]="product.availability === 'No Disponible' && !storeSvc.configuracionSitio.SuperarInventario"
                    type="button" title="Add to cart" class="btn btn-primary btn-sm btn-svg-icon" [class.btn-loading]="addingToCart"
                    (click)="addToCart()">
                    <app-icon name="cart-16" size="16"></app-icon>
                </button>
            </div>
            
            <!-- <button class="btn btn-primary product-card__addtocart" type="button" appClick  [disabled]=" product.availability === 'No Disponible' && !this.storeSvc.configuracionSitio.SuperarInventario" (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">Agregar al Carro</button> -->
            <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">Agregar al Carro</button>
            <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" aria-label="Agregar a la Lista de Deseos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist, 'wishlist' : esFavorito}">
                <app-icon [ngClass]="{'wishlist' : esFavorito}" name="wishlist-16" size="16"></app-icon>
                <span [ngClass]="{'wishlist' : esFavorito}" class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
            </button>
            <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" aria-label="Agregar a la Lista para Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}" *ngIf="storeSvc.configuracionSitio.VerCompararProductos">
                <app-icon name="compare-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
            </button>
        </div>
    </div>
</div>
