export class ConfiguracionSitio {
    address: string;
    email: string;
    phone: string;
    hours: string;
    scrmapa: string;
    VerProductosDestacados: boolean;
    VerMasVendidos: boolean;
    VerCategoriasPopulares: boolean;
    VerRecienllegados: boolean;
    VerUltimasNoticias: boolean;
    VerMarcas: boolean;
    VerBLoqueValoradosEspecialesVendidos: boolean;
    VerBannerIntermedio: boolean;
    VerBannerInformacion: boolean;
    VerAcordeonInformacion :boolean;
    VerWppIcono: boolean;
    NumeroWpp: number;
    PasaleraContraEntrega: Boolean;
    PasaleraPSE: boolean;
    SuperarInventario: boolean;
    PasarelaTranferenciaBancaria: boolean;
    MostrarPreciosSinLogueo: boolean;
    CreacionDirectaClientes : boolean;
    PosicionamientoEnGoogle: string;
    VerSeguimientoPedidos : boolean;
    VerCompararProductos : boolean;
    VerContacto:boolean;
    VerNoticias: boolean;
    VerSuscribirse: boolean;
    scriptRastreo:string;
    AgenciaDefaul: string;
    AsesorPredeterminado: number;
    VerBontonAplicarCupon: boolean;
    VerFiltroMarcas: boolean;
    VerMarcaDetalleProducto: boolean;
    MensajePersonalizadoPago: boolean;
  }


